
<template>
  <div class="expend-container">
    <list
      ref="list"
      :tableRowClassName="tableRowClassName"
      :searchUrl="searchUrl"
      :headers="headers"
      :searchParams="searchParams"
      :hasExpendColumn="hasExpendColumn"
    >
      <template #operateSlot="scope">
        <v-button
          text="终止发放"
          type="text"
          @click="stop(scope.row)"
          v-if="scope.row.status == 1"
        ></v-button>
      </template>
      <template #expendSlot="scope">
        <div class="grant-wrapper">
          <div class="grant-title" >发放计划</div>
          <div
            class="grant-each"
            v-for="(item, index) in scope.row.integralWaitGrantPlan"
          >
            <div>{{ item.nextGrantTime }}</div>
            <div>+{{ item.intNum }}</div>
          </div>
        </div>
      </template>
    </list>
  </div>
</template>

<script>
import { personalIntegralWaitGrant, stopURL } from "./api";
import { grantStatusMap } from "./map";
export default {
  name: "IntegraSendRecords",
  data() {
    return {
      hasExpendColumn: true,
      searchUrl: personalIntegralWaitGrant,
      headers: [
        {
          prop: "remark",
          label: "任务名称",
        },
        {
          prop: "intNum",
          label: "待发放积分",
          formatter: (row) => {
            return row.intNum;
          },
        },
        {
          prop: "status",
          label: "发放状态",
          formatter: (row) => {
            return grantStatusMap[row.status];
          },
        },
        {
          prop: "grantEndTime",
          label: "发放结束时间",
        }
      ],
      searchParams: {
        userId: this.$route.query.id,
        regionId: this.$route.query.regionId
      }
    }
  },
  mounted () {
    // 设置title
    this.$setBreadcrumb('个人积分待发放')
  },
  methods: {
    tableRowClassName({ row }) {
      console.log(row, "123424");
      if (row.status === 1) {
        return "";
      } else {
        return "expend-hide";
      }
    },
    async stop(row) {
      let isConfirm = await this.$confirm("是否确认终止发放?");
      if (isConfirm) {
        this.$axios.post(stopURL + `?id=${row.id}`).then((res) => {
          if (res.status === 100) {
            this.$refs.list.searchData();
            this.$message({
              type: "success",
              message: "终止发放成功！",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .expend-hide {
  .el-table__expand-icon {
    display: none;
  }
}
.expend-container {
  overflow: scroll;
  .grant-wrapper {
    padding: 0 10px;
    .grant-title {
      padding-bottom: 5px;
    }
    .grant-each {
      display: flex;
      padding-top: 6px;
      margin-right: 8px !important;
      &.last-of-type {
        padding-right: 8px;
      }
      > div {
        flex: 1;
      }
    }
  }
}
</style>

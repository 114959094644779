<template>
  <div class="detail-container">
    <list :searchUrl="searchUrl" :searchParams="searchParams" :headers="headers" :hasOperateColumn="false">
      <!-- <template #headerSlot>
        <v-button text="返回" @click="returnClick" />
      </template> -->
    </list>
  </div>
</template>

<script>
import { personalIntegraDetails } from './api'
import { _localStorage } from 'common/utils'
export default {
  name: 'PersonalIntegraDetail',
  data () {
    return {
      userInfo: {},
      searchParams: {
        userId: '',
        regionId: ''
      },
      searchUrl: personalIntegraDetails,
      headers: [
        {
          prop: 'serialNumber',
          label: '发放/回收流水号'
        },
        {
          prop: 'remark',
          label: '事件'
        },
        {
          prop: 'intNum',
          label: '积分变化',
          formatter: (row, prop) => {
            if (row.type === 0) {
              return `+${row[prop]}`
            } else {
              return `-${row[prop]}`
            }
          }
        },
        {
          prop: 'currentIntegral',
          label: '当前积分'
        },
        {
          prop: 'intime',
          label: '操作时间'
        }
      ]
    }
  },
  mounted () {
    // 设置title
    this.$setBreadcrumb('个人积分明细')
    this.handleQueryParams()
  },
  methods: {
    returnClick () {
      this.$router.back()
    },

    handleQueryParams () {
      const { userInfo, id,regionId } = this.$route.query
      this.userInfo = JSON.parse(userInfo)
      this.searchParams.userId = id
      this.searchParams.regionId = regionId
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-container {
  width: 100%;
  box-sizing: border-box;
  .list-container {
    height: 85%;
  }
  .user-info {
    width: 97.5%;
    display: flex;
    font-size: 25px;
    font-weight: bold;
    padding: 0 20px;
    margin-left: 20px;
    box-sizing: border-box;
    background-color: rgba(251, 251, 251, 1);
    color: #666;
    height: 100px;
    align-items: center;
    border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    & div {
      margin-right: 80px;
    }
  }
}

</style>

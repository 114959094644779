<template>
  <div class="detail-container">
    <div class="detail-right">
      <v-button text="返回" @click="returnClick()"></v-button>
    </div>
    <div class="user-info">
      <div class="username">姓名：{{ userInfo.userName }}</div>
      <div class="phone-number">手机号：{{ userInfo.phone }}</div>
      <div class="username">积分种类：{{ userInfo.sourceType }}</div>
      <div class="phone-number">积分运营主体：{{ userInfo.regionName }}</div>
    </div>
    <tabs-panel
      ref="tabs"
      class="tabs-panel"
      :tabs="tabs"
      :keepAlive="false"
      :activeLabel.sync="activeLabel"
    />
  </div>
</template>

<script>
import { _localStorage } from "common/utils";
import { TabsPanel } from "components/bussiness";
import IntegraRecord from "./PersonalIntegraRecord";
import IntegraWaitSend from "./PersonalIntegraWaitSend";
export default {
  name: "PersonalIntegraDetail",
  components: {
    TabsPanel,
  },
  data() {
    return {
      activeLabel: "积分明细",
      tabs: [
        {
          label: "积分明细",
          component: IntegraRecord,
        },
        {
          label: "待发放",
          component: IntegraWaitSend,
        },
      ],
      userInfo: {},
    };
  },
  mounted() {
    // 设置title
    // this.$setBreadcrumb('个人积分详情')
    this.handleQueryParams();
  },
  methods: {
    returnClick() {
      let from = this.$route.query.from;
      if (from == "send") {
        this.$router.push({
          path: "integraSendRecords",
        });
      } else if (from == "expend") {
        this.$router.push({
          path: "integraExpendRecords",
        });
      } else {
        this.$router.push({
          path: "personalIntegraLists",
        });
      }
    },

    handleQueryParams() {
      const { userInfo, id } = this.$route.query;
      this.userInfo = JSON.parse(userInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  width: 100%;
  box-sizing: border-box;
  overflow: scroll;
  .detail-right {
    text-align: right;
    padding: 0 20px 20px 0;
  }
  .list-container {
    height: 85%;
  }
  .user-info {
    width: 97.5%;
    display: flex;
    font-size: 25px;
    font-weight: bold;
    padding: 0 20px;
    margin-left: 20px;
    box-sizing: border-box;
    background-color: rgba(251, 251, 251, 1);
    color: #666;
    height: 100px;
    align-items: center;
    border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    margin-bottom: 20px;
    & div {
      margin-right: 80px;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c(
        "div",
        { staticClass: "detail-right" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: {
              click: function ($event) {
                return _vm.returnClick()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "user-info" }, [
        _c("div", { staticClass: "username" }, [
          _vm._v("姓名：" + _vm._s(_vm.userInfo.userName)),
        ]),
        _c("div", { staticClass: "phone-number" }, [
          _vm._v("手机号：" + _vm._s(_vm.userInfo.phone)),
        ]),
        _c("div", { staticClass: "username" }, [
          _vm._v("积分种类：" + _vm._s(_vm.userInfo.sourceType)),
        ]),
        _c("div", { staticClass: "phone-number" }, [
          _vm._v("积分运营主体：" + _vm._s(_vm.userInfo.regionName)),
        ]),
      ]),
      _c("tabs-panel", {
        ref: "tabs",
        staticClass: "tabs-panel",
        attrs: {
          tabs: _vm.tabs,
          keepAlive: false,
          activeLabel: _vm.activeLabel,
        },
        on: {
          "update:activeLabel": function ($event) {
            _vm.activeLabel = $event
          },
          "update:active-label": function ($event) {
            _vm.activeLabel = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }